// ./src/index.tsx
import React, { useState, useEffect } from "react"
import { createRoot } from "react-dom/client"
import { BrowserRouter as Router, Routes, Route, useLocation } from "react-router-dom"
import "./i18n"
import { useTranslation } from "react-i18next"
import App from "./App"
import Login from "./components/Login"
import Waiting from "./components/Waiting"
import Welcome from "./components/Welcome"
import NoAuth from "./components/NoAuth"
import { tokenVerify } from "./services/apiService"
import { CssBaseline } from "@material-ui/core"
import { ThemeProvider, createTheme } from "@material-ui/core/styles"
import { RecoilRoot, useRecoilState } from "recoil"
import {
  defaultUseSomeoneDotBotrunAtUrl,
  userAuthState,
  usernameForApiConcateState,
  userLogoutState,
} from "./recoil/atoms"
import Loading from "./components/Loading"
import { QueryClientProvider, QueryClient } from "react-query"

const container = document.getElementById("root")
const root = createRoot(container!)
const theme = createTheme({
  palette: {
    type: "dark",
  },
})

// 2023-12-24 23:50 bowen, 本地測試時如果 / 要自動變成 /botrun 不然會空畫面
const basename = process.env.REACT_APP_BASENAME
if (window.location.pathname === "/" && basename !== "/") {
  window.location.href = window.location.origin + basename
}

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false,
      refetchOnWindowFocus: false,
    },
  },
})

const Main = () => {
  const { t } = useTranslation()
  const [user, setUser] = useRecoilState(userAuthState)
  const [, setDefaultBotrun] = useRecoilState(defaultUseSomeoneDotBotrunAtUrl)
  const [, setUsernameForApiConcate] = useRecoilState(usernameForApiConcateState)
  const [isUserLogout] = useRecoilState(userLogoutState)
  const location = useLocation()

  const params = new URLSearchParams(location.search)
  const botrunToken = params.get("botrun_token")
  const username = params.get("username")
  const defaultBotrun = params.get("default_botrun") || "index"
  const useSSO = process.env.REACT_APP_USE_SSO === "True"
  const useFiefAuth = process.env.REACT_APP_USE_FIEF_AUTH === "True"
  const [loading, setLoading] = useState(false)

  const verifyToken = async (botrunToken: string) => {
    try {
      setLoading(true)
      const data = await tokenVerify(botrunToken, defaultBotrun, username?.toString())
      if (data.is_success) {
        setUser({
          token: botrunToken,
          type: "bearer",
          username: data.username,
        })
      } else {
        setUser(null)
      }
      setLoading(false)
    } catch (error) {
      console.error("[index] checkBotrunTokenValidity / error:", error)
      setUser(null)
      setLoading(false)
    }
  }

  useEffect(() => {
    const checkToken = async () => {
      // 000: check if isUserLogout
      if (isUserLogout) {
        return
      }

      // 001: 檢查 botrun_token
      if (botrunToken) {
        await verifyToken(botrunToken)
        return // 有 botrun_token 就直接返回，不再進行 user.token 驗證
      }

      // console.log("[index/Main] useEffect / user:", user);
      // 002: 檢查 user.token
      if (user && user.token !== undefined) {
        setLoading(true)
        console.log("[index/Main] useEffect[user?.token] / defaultBotrun:", defaultBotrun)
        const data = await tokenVerify(user.token, defaultBotrun, user.username)

        if (data.is_success) {
          // 驗證成功，並檢查 username 是否變更
          setUsernameForApiConcate(data.username)
          if (data.username !== user.username) {
            setUser({
              token: user.token,
              type: "bearer",
              username: data.username,
            })
          }
        } else {
          setUser(null)
        }
        setLoading(false)
      }
    }

    // 執行 token 檢查
    checkToken()

    // 如果有 username，設置 API 串接所需的 username
    if (username) {
      setUsernameForApiConcate(username)
    }

    // 設定 defaultBotrun
    setDefaultBotrun(defaultBotrun)

    // return () => {
    //   console.log("Main component is either unmounting or the effect is re-running");
    // };
  }, [botrunToken, user?.token, username, defaultBotrun])

  if (loading) {
    return <Loading text={t("Loading...")} />
  }

  if (isUserLogout) {
    return <Loading text={t("System Logging Out...")} />
  }

  return (
    <QueryClientProvider client={queryClient}>
      <Routes>
        {useSSO ? (
          <>
            <Route path="/NoAuth" element={user !== null ? <App /> : <NoAuth />} />
            <Route path="/" element={user !== null ? <App /> : <NoAuth />} />
          </>
        ) : (
          <>
            {useFiefAuth ? (
              <>
                <Route path="/welcome" element={user !== null ? <App /> : <Welcome />} />
                <Route path="/" element={user !== null ? <App /> : <Welcome />} />
              </>
            ) : (
              <>
                <Route path="/login" element={user !== null ? <App /> : <Login />} />

                <Route path="/" element={user !== null ? <App /> : <Login />} />
              </>
            )}
          </>
        )}
      </Routes>
    </QueryClientProvider>
  )
}

root.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <RecoilRoot>
        <Router basename={process.env.REACT_APP_BASENAME}>
          <Routes>
            <Route path="*" element={<Main />} />
            <Route path="/waiting" element={<Waiting />} />
          </Routes>
        </Router>
      </RecoilRoot>
    </ThemeProvider>
  </React.StrictMode>
)
