// src/App.tsx
import { useEffect, useState } from "react"
import ChatWindow from "./components/ChatWindow"
import ChatInput from "./components/ChatInput"
import { getApiUrl, uploadFiles } from "./services/apiService"
import WebSocketClient from "./components/WebSocketClient"
import { WEB_SOCKET_URL } from "./config/apiConfig"
import { useRecoilState, useRecoilValue } from "recoil"
import { usernameForApiConcateState, userAuthState } from "./recoil/atoms"
import SimpleDialog from "./components/SimpleDialog"
import { useTranslation } from "react-i18next"

const App = () => {
  const [params, setParams] = useState<string | null>(null)
  const [webSocketClient, setWebSocketClient] = useState<WebSocketClient | null>(null)
  const [isAssistantSpeaking, setIsAssistantSpeaking] = useState(false)
  const [externalInput, setExternalInput] = useState<string | null>("")
  const sendExternalMessage = (message: string) => {
    setExternalInput(message)
  }
  // 2023-12-29 bigcookie: 因為 userAuthState 已經會存放使用者的 token，所以可以直接使用不用在額外多一個 jwtTokenState
  // const jwtToken = useRecoilValue(jwtTokenState);
  const [user, setUser] = useRecoilState(userAuthState)
  const jwtToken = user?.token
  const usernameForApiConcate = useRecoilValue(usernameForApiConcateState)
  const { t } = useTranslation()

  const [isShowSimpleDialog, setShowSimpleDialog] = useState<boolean>(false)
  const handleSimpleDialogClose = () => {
    // console.log("[App.tsx] handleSimpleDialogClose");
    setShowSimpleDialog(false)
    setUser(null)
  }

  const onWebSocketError = () => {
    console.log("[App] onWebSocketError")
    // setShowSimpleDialog(true);
  }

  useEffect(() => {
    const onMessage = (data: string) => {
      // console.log("App.tsx, onMessage, data: ", data);
      const parsedData = JSON.parse(data)
      if (parsedData.finished) {
        setIsAssistantSpeaking(false)
        return
      }

      setMessages(prevMessages => {
        const newMessages = [...prevMessages]
        if (newMessages.length > 0) {
          if (newMessages[newMessages.length - 1].isUserMessage) {
            newMessages.push({
              message: parsedData.message,
              isUserMessage: false,
            })
          } else {
            const lastIndex = newMessages.length - 1
            const lastMessage = newMessages[lastIndex]
            newMessages[lastIndex] = {
              ...lastMessage,
              message: lastMessage.message + parsedData.message,
            }
          }
        } else {
          newMessages.push({
            message: parsedData.message,
            isUserMessage: false,
          })
        }

        return newMessages
      })
    }

    const strWebSocketUrl = getApiUrl(WEB_SOCKET_URL, usernameForApiConcate)
    const client = new WebSocketClient(strWebSocketUrl, onMessage, null, onWebSocketError)
    setWebSocketClient(client)
  }, [])

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search)
    setParams(urlParams.get("params"))
  }, [])

  useEffect(() => {
    const setVhVariable = () => {
      const vh = window.innerHeight * 0.01
      document.documentElement.style.setProperty("--vh", `${vh}px`)
    }

    setVhVariable()
    window.addEventListener("resize", setVhVariable)
    window.addEventListener("orientationchange", () => {
      setTimeout(setVhVariable, 150) // 手機轉向時，延遲更新，確保取得正確的高度值
    })

    return () => {
      window.removeEventListener("resize", setVhVariable)
    }
  }, [])

  const [messages, setMessages] = useState<Array<{ message: string; isUserMessage: boolean }>>([
    { message: "Hi👋", isUserMessage: false },
  ])

  const handleSendMessage = (message: string, isUserMessage = false) => {
    setMessages(prevMessages => [...prevMessages, { message, isUserMessage }])
  }

  const handleUploadFiles = async (files: FileList, sessionId: string) => {
    const data = new FormData()
    if (!jwtToken) {
      throw new Error("App.tsx, handleUploadFiles, JWT token is not available")
    }
    data.append("jwt_token", jwtToken)
    data.append("session_id", sessionId)
    for (let i = 0; i < files.length; i++) {
      data.append("files", files[i])
    }

    try {
      const response = await uploadFiles(data, usernameForApiConcate)
      let message = response.data.message
      let transcribe_audio_to_text = response.data.transcribe_audio_to_text
      if (transcribe_audio_to_text) {
        sendExternalMessage(transcribe_audio_to_text)
      } else {
        setIsAssistantSpeaking(false)
        setMessages(prevMessages => [...prevMessages, { message, isUserMessage: false }])
      }
    } catch (error) {
      setIsAssistantSpeaking(false)
      const retry = window.confirm("Error uploading files. Do you want to retry?")
      if (retry) {
        handleUploadFiles(files, sessionId)
      }
    }
  }

  return (
    <div
      style={{
        height: "calc(var(--vh, 1vh) * 100)",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <ChatWindow messages={messages} />
      <ChatInput
        onSendMessage={handleSendMessage}
        onUploadFiles={handleUploadFiles}
        messages={messages}
        setMessages={setMessages}
        params={params}
        webSocketClient={webSocketClient}
        isAssistantSpeaking={isAssistantSpeaking}
        setIsAssistantSpeaking={setIsAssistantSpeaking}
        externalInput={externalInput}
      />
      <SimpleDialog
        title={t("Service Interruption")}
        onClose={handleSimpleDialogClose}
        open={isShowSimpleDialog}
        message={t("Unable to connect to Botrun service, please reauthenticate.")}
        buttonLabel={t("Confirm")}
      />
    </div>
  )
}

export default App
