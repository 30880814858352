import Button from "@mui/material/Button"
import Dialog from "@mui/material/Dialog"
import DialogActions from "@mui/material/DialogActions"
import DialogContent from "@mui/material/DialogContent"
import DialogContentText from "@mui/material/DialogContentText"
import DialogTitle from "@mui/material/DialogTitle"
import { useTranslation } from "react-i18next"

type DialogPorps = {
  open: boolean
  setOpen: Function
  title: string
  description?: string
  onConfirm: Function
}

export default function AlertDialog(props: DialogPorps) {
  const { t } = useTranslation()
  const handleClose = () => {
    props.setOpen(false)
  }

  return (
    <Dialog
      open={props.open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title" sx={{ minWidth: "250px" }}>
        {props.title}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">{props.description}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          variant="outlined"
          onClick={() => {
            handleClose()
            props.onConfirm()
          }}
          autoFocus
        >
          {t('OK')}
        </Button>
        <Button variant="outlined" onClick={handleClose}>
          {t('Cancel')}
        </Button>
      </DialogActions>
    </Dialog>
  )
}
