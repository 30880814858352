import { useState } from "react"
import { IconButton } from "@material-ui/core"
import { useRecoilState, useRecoilValue } from "recoil"
import { userAuthState, usernameForApiConcateState, userLogoutState } from "../recoil/atoms"
import LogoutIcon from "@mui/icons-material/Logout"
import Dialog from "./Dialog"
import { useTranslation } from "react-i18next"
import { API_CORE_BASE_URL, API_BASE_URL } from "../config/apiConfig";
import { useNavigate } from "react-router-dom";

export default function LogoutButton() {
  const [, setUser] = useRecoilState(userAuthState);
  const [, setUserLogoutState] = useRecoilState(userLogoutState);
  const usernameForApiConcate = useRecoilValue(usernameForApiConcateState);
  const [open, setOpen] = useState(false)
  const { t } = useTranslation()
  const navigate = useNavigate();
  const useFiefAuth = process.env.REACT_APP_USE_FIEF_AUTH === "True"; 

  const handleLogout = () => {
    if (useFiefAuth) {
      setUserLogoutState(true);
    }

    setUser(null);

    if (useFiefAuth) {
      const logoutUrl = (process.env.REACT_APP_USE_DOCKER_IN_DOCKER === 'True' ? API_CORE_BASE_URL : API_BASE_URL) + '/api/botrun/logout?username=' + usernameForApiConcate;
      window.location.href = logoutUrl
    } else {
      navigate("/");
    }
  }

  // useEffect(() => {
  //   console.log("[LogoutButton] user:", user);
  // }, [user]);

  return (
    <>
      <IconButton size="medium" onClick={() => setOpen(true)}>
        <LogoutIcon />
      </IconButton>
      <Dialog open={open} setOpen={setOpen} title={t("Are you sure you want to log out?")} onConfirm={handleLogout} />
    </>
  )
}
